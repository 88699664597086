<template>
  <div class="widget-inner">
    <chart v-if="!!data && data.current_period.length > 0" class="chart-elem" :width="null" :height="null" :data="data" />
    <div class="empty" v-else>Not enough data for this date range.</div>
  </div>
</template>

<script>
import Chart from './_chart.vue'

export default {
  props: ['data'],
  components: {
    Chart
  },
  mounted() {
    this.$emit('loaded')
  }
}
</script>

<style lang="scss" scoped>
.chart-elem {
  position: relative;
}
.empty {
  text-align: center;
  color: $muted-text;
}
</style>